/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ImportUserRequestData } from './importUserRequestData';
import { RequestImportType } from './requestImportType';
import { RoleKey } from './roleKey';

export interface ImportUserRequest { 
    type: RequestImportType;
    role: RoleKey;
    data?: Array<ImportUserRequestData>;
}