/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateAnswerQuestionRequest } from './createAnswerQuestionRequest';

export interface CreateQuestionRequest { 
    type?: string;
    content?: string;
    groupId?: number;
    attachmentId: number;
    questionTagIds?: Array<number>;
    answers?: Array<CreateAnswerQuestionRequest>;
}