/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CommonAuthUserGroup } from './commonAuthUserGroup';

export interface LearningStudentPrivateResponse { 
    id: number;
    avatar?: string;
    fullName?: string;
    phone?: string;
    email?: string;
    birthDay?: Date;
    gender?: number;
    groups?: Array<CommonAuthUserGroup>;
    percent?: number;
    completed?: boolean;
    lastViewAt?: Date;
}