/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { User } from './user';
import { UserHasGroup } from './userHasGroup';

export interface OrganizationGroup { 
    id: number;
    name?: string;
    color?: string;
    organizationId: number;
    creatorId?: number;
    createdAt: Date;
    updatedAt: Date;
    trashedAt?: Date;
    deletedAt?: Date;
    creator?: User;
    organization?: Organization;
    userHasGroups?: Array<UserHasGroup>;
}