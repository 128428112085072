/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OrgCourseConfigResponse } from './orgCourseConfigResponse';
import { OrgExamConfigResponse } from './orgExamConfigResponse';
import { OrgQuestionConfigResponse } from './orgQuestionConfigResponse';
import { OrgStoreConfigResponse } from './orgStoreConfigResponse';

export interface OrgConfigResponse { 
    exam?: OrgExamConfigResponse;
    question?: OrgQuestionConfigResponse;
    course?: OrgCourseConfigResponse;
    fileManager?: OrgStoreConfigResponse;
}