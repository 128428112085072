/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { ExamPartAttribute } from './examPartAttribute';
import { ExamPartConfig } from './examPartConfig';
import { TypeExam } from './typeExam';
import { UserExamPart } from './userExamPart';
import { UserExamPartConfig } from './userExamPartConfig';
import { UserExamQuestion } from './userExamQuestion';
import { UserExamQuestionAnswer } from './userExamQuestionAnswer';

export interface ExamPart { 
    id: number;
    partName?: string;
    order: number;
    examId?: number;
    typeExamId?: number;
    creatorId: number;
    waitingSecond: number;
    durationMinute: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    exam?: Exam;
    typeExam?: TypeExam;
    examPartAttributes?: Array<ExamPartAttribute>;
    examPartConfigs?: Array<ExamPartConfig>;
    userExamPartConfigs?: Array<UserExamPartConfig>;
    userExamParts?: Array<UserExamPart>;
    userExamQuestionAnswers?: Array<UserExamQuestionAnswer>;
    userExamQuestions?: Array<UserExamQuestion>;
}