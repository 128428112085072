/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { User } from './user';

export interface UserPackage { 
    id: number;
    userId: number;
    questIsCorrect: number;
    questIsHelp: number;
    questIsVideo: number;
    organizationId: number;
    expiredAt?: Date;
    createdAt: Date;
    updatedAt: Date;
    user?: User;
}