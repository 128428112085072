/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseStudentLearningActionResponse } from './courseStudentLearningActionResponse';

export interface CourseStudentLearningChapterResponse { 
    id: number;
    order: number;
    courseId: number;
    title?: string;
    parentId?: number;
    mediaIntroduceEmbed?: string;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    children?: Array<CourseStudentLearningChapterResponse>;
    actions?: Array<CourseStudentLearningActionResponse>;
}