/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Answer } from './answer';
import { OriginFile } from './originFile';
import { QuestionAttachment } from './questionAttachment';
import { QuestionGroup } from './questionGroup';
import { QuestionTag } from './questionTag';
import { UserAccessQuestion } from './userAccessQuestion';

export interface Question { 
    id: number;
    folderId: number;
    content?: string;
    helpContent?: string;
    helpVideo?: string;
    type?: string;
    attachmentId?: number;
    groupId?: number;
    originId?: number;
    originPos?: number;
    groupPos: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    trashedAt?: Date;
    deletedAt?: Date;
    attachment?: QuestionAttachment;
    group?: QuestionGroup;
    origin?: OriginFile;
    answers?: Array<Answer>;
    questionTags?: Array<QuestionTag>;
    userAccessQuestions?: Array<UserAccessQuestion>;
}