/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Account } from './account';
import { Organization } from './organization';
import { OrganizationGroup } from './organizationGroup';
import { UserHasGroup } from './userHasGroup';
import { UserHasPermission } from './userHasPermission';
import { UserHasRole } from './userHasRole';
import { UserPackage } from './userPackage';

export interface AuthLoginResponse { 
    id: number;
    code?: string;
    organizationId: number;
    email?: string;
    phone?: string;
    avatar?: string;
    fullName?: string;
    birthday?: Date;
    gender?: number;
    address?: string;
    nation?: string;
    religion?: string;
    schoolName?: string;
    facebookLink?: string;
    creatorId: number;
    createdAt: Date;
    updatedAt: Date;
    trashedAt?: Date;
    deletedAt?: Date;
    organization?: Organization;
    userPackage?: UserPackage;
    accounts?: Array<Account>;
    organizationGroups?: Array<OrganizationGroup>;
    userHasGroupCreators?: Array<UserHasGroup>;
    userHasGroupUsers?: Array<UserHasGroup>;
    userHasPermissions?: Array<UserHasPermission>;
    userHasRoles?: Array<UserHasRole>;
    accountId: number;
    accountType?: string;
    accountUsername?: string;
    rememberToken?: string;
}