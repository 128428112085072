/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Question } from './question';

export interface UserAccessQuestion { 
    id: number;
    userId: number;
    questionId: number;
    isContent: number;
    isCorrect: number;
    isHelp: number;
    isVideo: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    question?: Question;
}