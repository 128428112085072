/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { Permission } from './permission';
import { User } from './user';

export interface UserHasPermission { 
    id: number;
    userId: number;
    permissionId: number;
    organizationId: number;
    creatorId: number;
    createdAt: Date;
    updatedAt: Date;
    organization?: Organization;
    permission?: Permission;
    user?: User;
}