/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Question } from './question';
import { QuestionAttachment } from './questionAttachment';

export interface QuestionGroup { 
    id: number;
    content?: string;
    attachmentId?: number;
    originId?: number;
    originPos?: string;
    creatorId: number;
    organizationId: number;
    isFixed: number;
    isPin: number;
    createdAt: Date;
    updatedAt: Date;
    trashedAt?: Date;
    deletedAt?: Date;
    attachment?: QuestionAttachment;
    questions?: Array<Question>;
}