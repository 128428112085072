/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { ExamPart } from './examPart';
import { ExamPartAttribute } from './examPartAttribute';
import { TypeExam } from './typeExam';
import { UserExamPartConfig } from './userExamPartConfig';

export interface ExamPartConfig { 
    id: number;
    name?: string;
    examId?: number;
    typeExamId?: number;
    partId: number;
    folderId?: number;
    attributeId?: number;
    turnId?: number;
    folderCountQuestion?: number;
    questionId?: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    attribute?: ExamPartAttribute;
    exam?: Exam;
    part?: ExamPart;
    typeExam?: TypeExam;
    userExamPartConfigs?: Array<UserExamPartConfig>;
}