/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseAction } from './courseAction';
import { CourseAssignment } from './courseAssignment';
import { CourseAssignmentGroup } from './courseAssignmentGroup';
import { CourseChapter } from './courseChapter';
import { LearningAction } from './learningAction';
import { LearningLog } from './learningLog';

export interface Course { 
    id: number;
    title?: string;
    thumb?: string;
    description?: string;
    videoInstructEmbed?: string;
    price: number;
    priceDiscount?: number;
    folderId: number;
    isPublish: boolean;
    publishAt?: Date;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    trashedAt?: Date;
    deletedAt?: Date;
    courseActions?: Array<CourseAction>;
    courseAssignmentGroups?: Array<CourseAssignmentGroup>;
    courseAssignments?: Array<CourseAssignment>;
    courseChapters?: Array<CourseChapter>;
    learningActions?: Array<LearningAction>;
    learningLogs?: Array<LearningLog>;
}