/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Question } from './question';

export interface OriginFile { 
    id: number;
    folderId: number;
    url?: string;
    name?: string;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    questions?: Array<Question>;
}