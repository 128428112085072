/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RoleHasPermission } from './roleHasPermission';
import { UserHasRole } from './userHasRole';

export interface Role { 
    id: number;
    key?: string;
    name?: string;
    createdAt: Date;
    updatedAt: Date;
    roleHasPermissions?: Array<RoleHasPermission>;
    userHasRoles?: Array<UserHasRole>;
}