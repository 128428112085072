/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExamPart } from './examPart';
import { ExamPartConfig } from './examPartConfig';
import { UserExam } from './userExam';
import { UserExamPart } from './userExamPart';

export interface UserExamPartConfig { 
    id: number;
    userId: number;
    userExamId: number;
    userExamPartId: number;
    partId: number;
    partConfigId: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    part?: ExamPart;
    partConfig?: ExamPartConfig;
    userExam?: UserExam;
    userExamPart?: UserExamPart;
}