/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Course } from './course';
import { CourseChapter } from './courseChapter';
import { LearningAction } from './learningAction';

export interface CourseAction { 
    id: number;
    isIntroduce: boolean;
    type?: string;
    courseId: number;
    chapterId: number;
    data?: string;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    chapter?: CourseChapter;
    course?: Course;
    learningActions?: Array<LearningAction>;
}