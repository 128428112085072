/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseChapterResponse } from './courseChapterResponse';

export interface CourseResponse { 
    id: number;
    title?: string;
    thumb?: string;
    description?: string;
    videoInstructEmbed?: string;
    price: number;
    priceDiscount?: number;
    folderId: number;
    creatorId: number;
    organizationId: number;
    isPublish: boolean;
    publishAt?: Date;
    createdAt: Date;
    updatedAt: Date;
    courseChapters?: Array<CourseChapterResponse>;
}