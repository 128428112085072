/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Answer } from './answer';
import { Tag } from './tag';

export interface AnswerTag { 
    id: number;
    answerId: number;
    tagId: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    answer?: Answer;
    tag?: Tag;
}