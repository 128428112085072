/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExamAssignment } from './examAssignment';
import { ExamAssignmentGroup } from './examAssignmentGroup';
import { ExamPart } from './examPart';
import { ExamPartAttribute } from './examPartAttribute';
import { ExamPartConfig } from './examPartConfig';
import { TypeExam } from './typeExam';
import { UserExam } from './userExam';
import { UserExamPart } from './userExamPart';
import { UserExamQuestion } from './userExamQuestion';
import { UserExamQuestionAnswer } from './userExamQuestionAnswer';
import { UserInfoResponse } from './userInfoResponse';

export interface ExamResponse { 
    id: number;
    folderId: number;
    thumb?: string;
    name?: string;
    typeExamId: number;
    description?: string;
    isPublic: number;
    isAssignment: number;
    isMultipleParts: number;
    countParts: number;
    countQuestions: number;
    startAt?: Date;
    endAt?: Date;
    publicedAt?: Date;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    trashedAt?: Date;
    deletedAt?: Date;
    typeExam?: TypeExam;
    examAssignmentGroups?: Array<ExamAssignmentGroup>;
    examAssignments?: Array<ExamAssignment>;
    examPartAttributes?: Array<ExamPartAttribute>;
    examPartConfigs?: Array<ExamPartConfig>;
    examParts?: Array<ExamPart>;
    userExamParts?: Array<UserExamPart>;
    userExamQuestionAnswers?: Array<UserExamQuestionAnswer>;
    userExamQuestions?: Array<UserExamQuestion>;
    userExams?: Array<UserExam>;
    creator?: UserInfoResponse;
}