/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RoleHasPermission } from './roleHasPermission';
import { UserHasPermission } from './userHasPermission';

export interface Permission { 
    id: number;
    name?: string;
    key?: string;
    createdAt: Date;
    updatedAt: Date;
    roleHasPermissions?: Array<RoleHasPermission>;
    userHasPermissions?: Array<UserHasPermission>;
}