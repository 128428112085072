/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RequestImportType } from './requestImportType';
import { RoleKey } from './roleKey';
import { UpsertImportUserRequestData } from './upsertImportUserRequestData';

export interface UpsertImportUserRequest { 
    type: RequestImportType;
    role: RoleKey;
    users?: Array<UpsertImportUserRequestData>;
}