/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Course } from './course';
import { CourseAction } from './courseAction';
import { CourseAssignment } from './courseAssignment';
import { CourseChapter } from './courseChapter';
import { LearningLog } from './learningLog';

export interface LearningAction { 
    id: number;
    studentId: number;
    courseId: number;
    assignId: number;
    chapterId: number;
    actionId?: number;
    logId?: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    action?: CourseAction;
    assign?: CourseAssignment;
    chapter?: CourseChapter;
    course?: Course;
    log?: LearningLog;
}