/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Account } from './account';
import { OrganizationDomain } from './organizationDomain';
import { OrganizationGroup } from './organizationGroup';
import { User } from './user';
import { UserHasGroup } from './userHasGroup';
import { UserHasPermission } from './userHasPermission';
import { UserHasRole } from './userHasRole';

export interface Organization { 
    id: number;
    name?: string;
    createdAt: Date;
    updatedAt: Date;
    accounts?: Array<Account>;
    organizationDomains?: Array<OrganizationDomain>;
    organizationGroups?: Array<OrganizationGroup>;
    userHasGroups?: Array<UserHasGroup>;
    userHasPermissions?: Array<UserHasPermission>;
    userHasRoles?: Array<UserHasRole>;
    users?: Array<User>;
}