/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { ExamAssignmentGroup } from './examAssignmentGroup';

export interface ExamAssignment { 
    id: number;
    groupId?: number;
    examId: number;
    studentId: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    exam?: Exam;
    group?: ExamAssignmentGroup;
}