/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseTypeStaticalPublicResponse } from './courseTypeStaticalPublicResponse';

export interface CourseStudentInfoPublicResponse { 
    id: number;
    title?: string;
    thumb?: string;
    description?: string;
    videoInstructEmbed?: string;
    price: number;
    priceDiscount?: number;
    folderId: number;
    publishAt?: Date;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    mediaStatical?: Array<CourseTypeStaticalPublicResponse>;
    isJoined: boolean;
    joinedAt?: Date;
    percent?: number;
    completed?: boolean;
    lastViewAt?: Date;
}