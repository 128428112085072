/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { ExamPart } from './examPart';
import { ExamPartConfig } from './examPartConfig';
import { TypeExam } from './typeExam';
import { UserExamPart } from './userExamPart';

export interface ExamPartAttribute { 
    id: number;
    examId?: number;
    typeExamId?: number;
    examPartId: number;
    name?: string;
    countRequired: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    exam?: Exam;
    examPart?: ExamPart;
    typeExam?: TypeExam;
    examPartConfigs?: Array<ExamPartConfig>;
    userExamParts?: Array<UserExamPart>;
}