/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { UserExamPart } from './userExamPart';
import { UserExamPartConfig } from './userExamPartConfig';
import { UserExamQuestion } from './userExamQuestion';
import { UserExamQuestionAnswer } from './userExamQuestionAnswer';
import { UserInfoResponse } from './userInfoResponse';
import { UserQuestionLog } from './userQuestionLog';

export interface UserExamResponse { 
    id: number;
    userId: number;
    examId: number;
    status?: string;
    startedAt: Date;
    lastPartSubmitedAt?: Date;
    submitedAt?: Date;
    totalCorrect: number;
    totalQuestion: number;
    completedAt?: Date;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    exam?: Exam;
    userExamPartConfigs?: Array<UserExamPartConfig>;
    userExamParts?: Array<UserExamPart>;
    userExamQuestionAnswers?: Array<UserExamQuestionAnswer>;
    userExamQuestions?: Array<UserExamQuestion>;
    userQuestionLogs?: Array<UserQuestionLog>;
    userInfo?: UserInfoResponse;
}