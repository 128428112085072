/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { ExamPart } from './examPart';
import { UserExam } from './userExam';
import { UserExamPart } from './userExamPart';
import { UserExamQuestionAnswer } from './userExamQuestionAnswer';
import { UserQuestionLog } from './userQuestionLog';

export interface UserExamQuestion { 
    id: number;
    userId: number;
    userExamId: number;
    userExamPartId: number;
    partId: number;
    examId: number;
    questionId: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    exam?: Exam;
    part?: ExamPart;
    userExam?: UserExam;
    userExamPart?: UserExamPart;
    userExamQuestionAnswers?: Array<UserExamQuestionAnswer>;
    userQuestionLogs?: Array<UserQuestionLog>;
}