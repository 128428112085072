/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserInfoResponse } from './userInfoResponse';

export interface StructureStoreCommonDetailResponse { 
    id: number;
    name?: string;
    parentId?: number;
    path?: string;
    creatorId: number;
    hasChild: boolean;
    createdAt: Date;
    updatedAt: Date;
    creator?: UserInfoResponse;
    children?: Array<StructureStoreCommonDetailResponse>;
}