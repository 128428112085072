/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { ExamPart } from './examPart';
import { ExamPartAttribute } from './examPartAttribute';
import { UserExam } from './userExam';
import { UserExamPartConfig } from './userExamPartConfig';
import { UserExamQuestion } from './userExamQuestion';
import { UserExamQuestionAnswer } from './userExamQuestionAnswer';
import { UserQuestionLog } from './userQuestionLog';

export interface UserExamPart { 
    id: number;
    examId: number;
    userId: number;
    userExamId: number;
    partId: number;
    attributeId?: number;
    status?: string;
    totalCorrect: number;
    totalQuestion: number;
    startedAt?: Date;
    endedAt?: Date;
    completedAt?: Date;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    attribute?: ExamPartAttribute;
    exam?: Exam;
    part?: ExamPart;
    userExam?: UserExam;
    userExamPartConfigs?: Array<UserExamPartConfig>;
    userExamQuestionAnswers?: Array<UserExamQuestionAnswer>;
    userExamQuestions?: Array<UserExamQuestion>;
    userQuestionLogs?: Array<UserQuestionLog>;
}