/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CommonAuthUserGroup } from './commonAuthUserGroup';

export interface CommonAuthUser { 
    id: number;
    code?: string;
    organizationId: number;
    avatar?: string;
    fullName?: string;
    birthday?: Date;
    gender?: number;
    address?: string;
    nation?: string;
    religion?: string;
    phone?: string;
    groups?: Array<CommonAuthUserGroup>;
}