/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PublicAnswerQuestionResponse } from './publicAnswerQuestionResponse';
import { PublicAttachmentQuestionResponse } from './publicAttachmentQuestionResponse';
import { PublicQuestionAccessResponse } from './publicQuestionAccessResponse';

export interface PublicQuestionResponse { 
    id: number;
    content?: string;
    helpContent?: string;
    helpVideo?: string;
    type?: string;
    attachmentId?: number;
    answerSelectedContent?: string;
    isCorrect?: boolean;
    isPin?: boolean;
    originId?: number;
    originPos?: number;
    originUrl?: string;
    answers?: Array<PublicAnswerQuestionResponse>;
    attachmentInfo?: PublicAttachmentQuestionResponse;
    childQuestions?: Array<PublicQuestionResponse>;
    access?: PublicQuestionAccessResponse;
}