/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserExamResponse } from './userExamResponse';

export interface UserExamResponseCommonGetLstResponse { 
    timer: number;
    objs?: Array<UserExamResponse>;
    total: number;
    searchString?: string;
    currentNumPage: number;
    afterNumPage: number;
    beforeNumPage: number;
    trash: boolean;
}