/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Course } from './course';
import { CourseAction } from './courseAction';
import { LearningAction } from './learningAction';

export interface CourseChapter { 
    id: number;
    order: number;
    courseId: number;
    title?: string;
    parentId?: number;
    mediaIntroduceEmbed?: string;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    course?: Course;
    parent?: CourseChapter;
    courseActions?: Array<CourseAction>;
    inverseParent?: Array<CourseChapter>;
    learningActions?: Array<LearningAction>;
}