/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseActionTypeDocumentEmbedModel } from './courseActionTypeDocumentEmbedModel';
import { CourseActionTypeVideoModel } from './courseActionTypeVideoModel';

export interface CourseActionResponse { 
    id: number;
    isIntroduce: boolean;
    type?: string;
    courseId: number;
    chapterId: number;
    videoData?: CourseActionTypeVideoModel;
    documentData?: CourseActionTypeDocumentEmbedModel;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
}