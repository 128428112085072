/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Course } from './course';
import { CourseAssignmentGroup } from './courseAssignmentGroup';
import { LearningAction } from './learningAction';
import { LearningLog } from './learningLog';

export interface CourseAssignment { 
    id: number;
    groupId?: number;
    courseId: number;
    studentId: number;
    logId?: number;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    course?: Course;
    group?: CourseAssignmentGroup;
    log?: LearningLog;
    learningActions?: Array<LearningAction>;
}