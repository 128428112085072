/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserExam } from './userExam';
import { UserExamPart } from './userExamPart';
import { UserExamQuestion } from './userExamQuestion';
import { UserExamQuestionAnswer } from './userExamQuestionAnswer';

export interface UserQuestionLog { 
    id: number;
    userId: number;
    questionId: number;
    selectAnswerId?: number;
    userExamId: number;
    userExamPartId: number;
    userExamQuestionId: number;
    selectAnswerContent?: string;
    isCorrect: number;
    hasChoose: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    confirmedAt?: Date;
    userExam?: UserExam;
    userExamPart?: UserExamPart;
    userExamQuestion?: UserExamQuestion;
    userExamQuestionAnswers?: Array<UserExamQuestionAnswer>;
}