/**
 * private-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Exam } from './exam';
import { ExamPart } from './examPart';
import { ExamPartAttribute } from './examPartAttribute';
import { ExamPartConfig } from './examPartConfig';

export interface TypeExam { 
    id: number;
    avatar?: string;
    name?: string;
    defaultDescription?: string;
    creatorId: number;
    organizationId: number;
    createdAt: Date;
    updatedAt: Date;
    examPartAttributes?: Array<ExamPartAttribute>;
    examPartConfigs?: Array<ExamPartConfig>;
    examParts?: Array<ExamPart>;
    exams?: Array<Exam>;
}